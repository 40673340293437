import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types"
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroller';
import Profile from '../components/Profile'

const searchIcon = () => <svg style={{ 'height': '25px', 'margin': 'auto' }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
</svg>

const Businesses = (props) => {
  const [businesses, setBusinesses] = useState([]);
  const [query, setQuery] = useState('')
  const [location, setLocation] = useState('')
  const [hasMoreItems, setHasMoreItems] = useState(true)
  const [tags, setTags] = useState([])
  const [queryTags, setQueryTags] = useState([])
  const [profileVisible, setProfileVisible] = useState(false)
  const [selected, setSelected] = useState('')

  useEffect(() => {
    axios.get(`/api/tags`)
    .then((response) => {
      setTags(response.data)
    })
  }, [])

  const createFetchUrl = (page = 1, e = null) => {
    let params = { page: page, query: query, location: location, tags: queryTags || ''}
    return `/businesses.json?${Object.keys(params).map(key => key + '=' + params[key]).join('&')}`
  }

  const loadItems = (page) => {
    if (page == 1) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString)
      const business = urlParams.get('business')
      if (business) {
        setSelected(urlParams.get('business'))
        setProfileVisible(true)
      }
    }

    axios.get(createFetchUrl(page))
    .then((response) => {
      const { total, entries } = response.data
      const combined = businesses.concat(entries.data)
      
      setBusinesses(combined)
      setHasMoreItems(total >= businesses.length && entries.data.length > 0)
    })
  }

  useEffect(() => {
    axios.get(createFetchUrl())
      .then((response) => {
        const { total, entries } = response.data
        setBusinesses(entries.data)
      })
  }, [queryTags]);

  const tagHandler = (e) => {
    e.preventDefault()
    const tag = e.target.innerText
    let tags
    
    if (queryTags.includes(tag)) {
      const tagIndex = queryTags.indexOf(tag)
      let newTags = queryTags.slice()
      newTags[tagIndex] = undefined
      setQueryTags(newTags.filter((x) => x))
    } else {
      setQueryTags([...queryTags, tag])
      loadItems()
    }
  }

  const queryHandler = (e) => {
    e.preventDefault()
    if (e.target.name == 'query-form') {
      setQuery(query)
    }

    if (e.target.name == 'location-form') {
      setLocation(location)
    }

    axios.get(createFetchUrl())
      .then((response) => {
        const { total, entries } = response.data
        setBusinesses(entries.data)
      })
  }

  const selectHandler = (e) => {
    e.preventDefault(); 
    setSelected(e.target.dataset.slug); 
    setProfileVisible(true)
    history.pushState(null, '', `${window.location.origin}/browse?business=${e.target.dataset.slug}`)

  }

  return (
    <main className="w-full">
      { selected && <Profile selected={selected} profileVisible={profileVisible} setProfileVisible={setProfileVisible} /> }

      <div className="rounded bg-white text-gray-600 w-full p-4 pt-2 grid gap-4 grid-cols-2">
        <div className="md:col-span-1 col-span-2">
          <label className="block mt-2 text-xs font-semibold text-gray-600 uppercase">Location</label>
          <form className="flex mt-2" onSubmit={queryHandler} id="location-form">
            <input type="text" onChange={(e) => setLocation(e.target.value)} id="location" placeholder="90210, Beverly Hills, etc.." className="mr-4 rounded block w-full p-3 text-gray-700 bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner" />
            <button type="submit" name="location-btn" className="self-auto rounded w-full py-3 font-sm text-white bg-indigo-600 shadow-lg focus:outline-none hover:bg-gray-900 hover:shadow-none" style={{maxWidth: '50px'}}>{searchIcon()}</button>
          </form>
        </div>

        <div className="md:col-span-1 col-span-2">
          <label className="block mt-2 text-xs font-semibold text-gray-600 uppercase">Restaurant name</label>
          <form className="flex mt-2" onSubmit={queryHandler} id="query-form">
            <input type="text" onChange={(e) => setQuery(e.target.value)} placeholder="Roscoe's, Serving Spoon, etc." className="mr-4 rounded block w-full p-3 text-gray-700 bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner" />
            <button type="submit" className="self-auto rounded w-full py-3 font-sm text-white bg-indigo-600 shadow-lg focus:outline-none hover:bg-gray-900 hover:shadow-none" style={{maxWidth: '50px'}}>{searchIcon()}</button>
          </form>
        </div>
      </div>

      <div className="flex mt-6">
        <section className="w-2/12">
          <h2 className="text-white font-medium mb-2 text-xl">Tags</h2>
          
          {
            tags && tags.map((tag) => <a href="#" onClick={tagHandler} className="tag flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 hover:bg-green-300 rounded-full m-1">
              {
                queryTags.includes(tag) && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              }
              { tag }
            </a>)
          }

        </section>
        <section className="w-10/12 wrap">
          <InfiniteScroll
              pageStart={0}
              loadMore={loadItems}
              hasMore={hasMoreItems}
              scrollThreshold={0.5}
              loader={<div className="loader" key={0}>Loading ...</div>}
          >
            {
              businesses && Array.isArray(businesses) && businesses.map((business, i) => {
                const { name, slug, locations, tags } = business.attributes

                return <div className="col-span-3 md:col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                          <div className="w-full flex items-center justify-between p-6 space-x-6">
                            <div className="flex-1 truncate">
                              <div className="flex items-center space-x-3">
                                <h3 className="text-gray-900 text-sm font-medium truncate">
                                  <a data-slug={slug} onClick={selectHandler} href={`businesses/${ slug }`}>{ name }</a>
                                </h3>
                              </div>
                              
                              <div className="mt-1 text-gray-500 text-sm truncate">
                                { locations && locations.slice(0, 1).map((l, i) => <div key={i}>
                                  { l.address_line_1 && <div className="font-xs">{l.address_line_1}</div> }
                                  { l.address_line_2 && <div className="font-xs">{l.address_line_2}</div> }
                                  { l.city && <span className="font-xs">{l.city}</span> }
                                  { l.city && l.state && ', ' }
                                  { l.state && <span className="font-xs">{l.state}</span> }
                                  { l.zip_code && <span className="font-xs"> {l.zip_code}</span> }
                                </div>
                                )}
                                { locations && locations.length > 1 ? `...and ${locations.length - 1} other locations` : ''}
                              </div>
                            </div>

                            {/* <div className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"></div> */}
                          </div>
                          <div className="p-3">
                            {
                              tags && tags.map((tag) => <a href="#" onClick={tagHandler} className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 hover:bg-green-200 rounded-full mr-2">{tag.name}</a>)
                            }
                          </div>
                        </div>
              })
            }
            </InfiniteScroll>
        </section>

        {/* <section className="w-3/12 pl-2">
          <div className="p-4 text-white">
            
          </div>
        </section> */}


      </div>
    </main>
  );
}

Businesses.propTypes = {
  businesses: PropTypes.array
};

export default Businesses

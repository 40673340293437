// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require('./menu')

import "../stylesheets/application" // ADD THIS LINE

document.addEventListener("turbolinks:load", () => {
  const foodTruckToggle = document.querySelector('#food-truck-toggle')
  
  foodTruckToggle && foodTruckToggle.addEventListener('click', (e) => {
    // document.querySelector('.address-info').style.display = e.target.checked ? 'none' : ''

    document.querySelectorAll('.address-info input.required').forEach((input) => {
      if (e.target.checked) {
        input.setAttribute('required', 'true')
      } else {
        input.removeAttribute('required')
      }
    })
  })
  
})
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Profile = (props) => {

  const [data, setData] = useState({})
  
  useEffect(() => {
    axios.get(`${window.location.origin}/api/${props.selected}`)
    .then((response) => {
      setData(response.data.data.data)
    })
  }, [props.selected])

  return (<section style={{top: 0, zIndex: 10 }} className={`fixed transform transition ease-in-out duration-500 sm:duration-700 ${ props.profileVisible ? 'translate-x-0' : 'translate-x-full'} inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16`} aria-labelledby="slide-over-heading">
         {/*
          Slide-over panel, show/hide based on slide-over state.
  
          Entering: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-full"
            To: "translate-x-0"
          Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-0"
            To: "translate-x-full"
        */}
        <div className="w-screen max-w-md">
          <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
            <div className="px-4 py-6 sm:px-6">
              <div className="flex items-start justify-between">
                <h2 id="slide-over-heading" className="text-lg font-medium text-gray-900">
                  {data.attributes && data.attributes.name} Profile
                </h2>
                <div className="ml-3 h-7 flex items-center">
                  <button onClick={() => { props.setProfileVisible(false) }} className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500">
                    <span className="sr-only">Close panel</span>
                    
                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            
            <div>
              <div className="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
                <dl className="space-y-8 px-4 sm:px-6 sm:space-y-6">
                  <div>
                    <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                      Locations
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                    { data.attributes && data.attributes.locations && data.attributes.locations.map((l, i) => <div key={i} class="mb-4">
                                  { l.address_line_1 && <div className="font-xs">{l.address_line_1}</div> }
                                  { l.address_line_2 && <div className="font-xs">{l.address_line_2}</div> }
                                  { l.city && <span className="font-xs">{l.city}</span> }
                                  { l.city && l.state && ', ' }
                                  { l.state && <span className="font-xs">{l.state}</span> }
                                  { l.zip_code && <span className="font-xs"> {l.zip_code}</span> }
                                </div>
                                )}
                    </dd>
                  </div>
                  
                  
                  {
                  ['instagram', 'twitter', 'facebook', 'website'].map((property) =>
                    <div> 
                      <dt className="capitalize text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                        { data && data.attributes && data.attributes[property] && property }
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                        {data && data.attributes && data.attributes[property]}
                      </dd>
                    </div>)
                  }
                </dl>
              </div>
            </div>
          
          </div>
        </div>
      </section>)
}

export default Profile
